#trip-list {
  .search-wrapper {
    max-width: 90%;
    right: 5%;
    left: 5%;
    justify-content: space-between;
    align-items: flex-end;

    .trip-search-container {
      display: flex;
      align-items: center;

      .search-input-container {
        min-width: 30em;
      }
    }
  }
}

#update-trip {
  .pd-left {
    padding-left: .7em;
  }

  .pd-right {
    padding-right: .7em;
  }

  .status-options {
    display: flex;
    justify-content: flex-end;

    .option {
      cursor: pointer;
      padding-left: 1em;
      &:hover {
        text-decoration: underline;
      }

      i {
        margin-left: 8px;
      }
    }
  }

  &.form-section {
    .form-actions {
      margin-top: 2rem;
    }
  }
}