
.list-row-loading {
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background-size: 200% 100%;
}

@keyframes placeHolderShimmer{
	0%{
		background-position: 200% 0
	}
	100%{
		background-position: -200% 0
	}
}