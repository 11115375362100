
#sidebar {
	width: $sidebar-width;
	height: 100%;
	overflow: auto;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;

	.sidebar-menu {
		width: 100%;
		margin: auto 0;
		
		.sidebar-block {
			width: 100%;
			display: block;
			text-align: center;
			padding: 12px 10px;
			position: relative;
			transition: background-color .2s;

			.sb-block-icon {
				svg {
					height: auto;
					width: 22px;
					fill: $secondary-color;
				}
			}

			.sb-block-title {
				color: $secondary-color;
				padding: 4px 0;
				font-size: 0.9rem;
			}

			&.active {
				background-color: darken($color: $primary-color, $amount: 6%);

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 4px;
					background-color: $yellow-color;
				}
			}

			&:hover {
				background-color: darken($color: $primary-color, $amount: 6%);
			}
		}
	}
}