
#profile-page {

    .profile-section {
        width: 50%;
        max-width: 580px;
        margin: 0 auto;
        z-index: 1;
        position: relative;
        background-color: $secondary-light-color;
        margin-top: 2.2em;
        padding: 1.5em;
    }

    .details {
        padding: 2em;

        &::after {
            content: "";
            clear: both;
            display: block;
        }

        .label {
            float: left;
            width: 30%;
            font-size: 1.1em;
            color: $secondary-dark-color;
            font-weight: bold;
        }

        .value {
            float: left;
            width: 70%;
            font-size: 1.1em;
            color: $primary-color;
        }
    }

    .separator {
        border-bottom: 1px solid $divider-color;
    }

    .logout {
        width: 122px;
        margin-left: 36px;
    }
}