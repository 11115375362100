// change and compile from : http://codepen.io/Dishant15/pen/XKoVRE?editors=1100

.loading-overlay {
    // position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // width: 100%;
    // height: 100%;
    // background-color: rgba(0, 0, 0, 0.7);
    transition: background-color .2s ease-out;
    // used to cender loader
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-anim {
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
  perspective: 800px;
  transform-style: preserve-3d;
  transform: translateZ(-100px) rotateY(-90deg) rotateX(90deg) rotateZ(90deg) scale(0.5);
  opacity: 0;
  transition: all .2s ease-out;

  .circle {
    width: 100%;
    height: 100%;
    animation: spin 5s linear infinite;
  } 

  .border {
    position: absolute;
    border-radius: 50%;
    border: 3px solid $secondary-light-color;
  } 

  .out {
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: spin 2s linear reverse infinite;
  } 

  .in {
    top: 18%;
    left: 18%;
    width: 64%;
    height: 64%;
    border-top-color: transparent;
    border-bottom-color: transparent;
    animation: spin 2s linear infinite;
  }

  .mid {
    top: 40%;
    left: 40%;
    width: 20%;
    height: 20%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: spin 1s linear infinite;
  }
}

.loading .loading-anim {
  margin-top: 30px;
  transform: translateZ(0) rotateY(0deg) rotateX(0deg) rotateZ(0deg) scale(1);
  opacity: 1;
}

.loading .loading-overlay {
  background: rgba(255, 255, 255, 0.5);
}

.dot {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $secondary-light-color;
  animation: jitter 5s ease-in-out infinite, fade-in-out 5s linear infinite;
}

.dot:nth-child(1) {
  top: 90px;
  left: 180px;
  animation-delay: 0s;
}

.dot:nth-child(2) {
  top: 135px;
  left: 168px;
  animation-delay: 0.41667s;
}

.dot:nth-child(3) {
  top: 168px;
  left: 135px;
  animation-delay: 0.83333s;
}

.dot:nth-child(4) {
  top: 180px;
  left: 90px;
  animation-delay: 1.25s;
}

.dot:nth-child(5) {
  top: 168px;
  left: 45px;
  animation-delay: 1.66667s;
}

.dot:nth-child(6) {
  top: 135px;
  left: 12px;
  animation-delay: 2.08333s;
}

.dot:nth-child(7) {
  top: 90px;
  left: 0px;
  animation-delay: 2.5s;
}

.dot:nth-child(8) {
  top: 45px;
  left: 12px;
  animation-delay: 2.91667s;
}

.dot:nth-child(9) {
  top: 12px;
  left: 45px;
  animation-delay: 3.33333s;
}

.dot:nth-child(10) {
  top: 0px;
  left: 90px;
  animation-delay: 3.75s;
}

.dot:nth-child(11) {
  top: 12px;
  left: 135px;
  animation-delay: 4.16667s;
}

.dot:nth-child(12) {
  top: 45px;
  left: 168px;
  animation-delay: 4.58333s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes jitter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.7, 0.7);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes fade-in-out {
  0% {
    opacity: 0.8;
  }
  25% {
    opacity: 0.2;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}


// dot loader for inline loading

.loading-dots {
	text-align: center;
	width: 100%;

	&.dots {
		animation: dot-keyframes 1.5s infinite ease-in-out;
		background-color: $secondary-light-color;
		border-radius: 10px;
		display: inline-block;
		height: 10px;
    	width: 10px;
		margin: 0 5px;
    
    &:nth-child(2) {
    	animation-delay: .5s;
    }
    
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}