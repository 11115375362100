$data-color: #808185;

#report-container {
  .report-data-container {
    .vehicle-refule-heading {
      color: $primary-color;
      font-weight: bold;
      font-size: 1.4em;
      text-align: center;
    }

    .vehicle-refule-table {
      padding-bottom: 3em;
      padding-top: 1.2em;

      &.last-row-bot {
        .vehicle-refule-row:last-child {
          border-bottom: 1px solid #e2e2e2;
        }
      }

      .vehicle-refule-row {
        display: flex;
        border: 1px solid #e2e2e2;
        border-bottom: none;

        &.header {
          color: $prime-font-color;
          font-weight: 600;
          border: none;
        }

        &.footer {
          color: $prime-font-color;
          font-weight: 600;
          border-bottom: 1px solid #e2e2e2;
        }

        .col {
          flex: 1;
          padding: 0.8em 1em;
          color: $data-color;
          text-align: center;

          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
}
