$data-color: #808185;
$highlight-font-color: #88b04b;

#report-container {
  .page-details-wrapper {
    padding: 1.6em;
    min-width: 1400px;
  }

  .report-info-text {
    text-align: center;
    padding: 2em;
    padding-top: 6em;
    color: $secondary-color;
  }

  .heading {
    text-align: center;
    color: $secondary-color;
    font-weight: bold;
    font-size: 1.8em;
    padding: 0.8em;
  }

  .trip-report-heading {
    text-align: center;
    color: $secondary-color;
    font-weight: bold;
    font-size: 1.6em;
    padding-bottom: .7em;
    margin-bottom: 1.3em;
    border-bottom: 1px solid $secondary-light-color;
  }

  .report-table-column-btns {
    .label {
      font-size: 0.9em;
      color: #b5b5b5;
      margin-left: 16px;
    }

    .action-wrapper {
      padding-top: 10px;
      padding-bottom: 16px;

      .action-btn {
        display: inline-block;
        width: auto;
        margin-right: 16px;
        margin-bottom: 10px;
      }
    }
  }

  .report-data-container {
    margin-top: 2em;
    padding: 2em;
    background-color: #fff;
    min-height: 50em;

    .reports-no-data {
      font-size: 1.8em;
      color: #b5b5b5;
      text-align: center;
      padding: 2.6em 0;
      font-weight: bold;
    }
  }

  .reports-chart-container {
    &.error-container {
      text-align: center;
      height: 150px;
      padding-top: 50px;
    }

    .reports-chart-no-data {
      font-size: 1.4em;
      color: #b5b5b5;
      text-align: center;
      padding: 2.6em 0;
    }

    .bar-chart-wrapper {
      .reports-chart-wrapper svg {
        border-right: 1px solid lightgray;
      }
    }

    .reports-chart-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .pie-content {
      display: flex;
      justify-content: center;
      width: 100%;

      .pie-chart {
        padding: 20px;
        flex: 1;
        min-width: 250px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .pie-chart-legend {
        padding: 20px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        min-width: 200px;

        .pie-legend {
          display: table;

          .pie-indicator-wrapper {
            width: 20px;
            display: table-cell;
            vertical-align: middle;

            .pie-indicator {
              height: 14px;
              width: 14px;
              border-radius: 50%;
            }
          }

          .pie-label {
            display: table-cell;
            vertical-align: middle;
            color: $data-color;
            font-variant-caps: all-small-caps;
            padding-bottom: 4px;
            font-size: 1.2em;
          }
        }
      }
    }

    %tick {
      font-size: 10px;
      fill: #808185;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .axes {
      .domain {
        stroke: grey;
      }
      .tick text {
        @extend %tick;
        font-weight: bold;
      }

      .tick-y text {
        @extend %tick;
      }
    }

    .grid {
      line {
        stroke: lightgray;
      }
    }

    .tracer {
      line {
        stroke-dasharray: 3 3;
        stroke: grey;
        stroke-width: 1px;
      }
      circle {
        cursor: pointer;
        stroke: $highlight-font-color;
        fill: $highlight-font-color;
      }
      text {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        stroke: #00aab2;
        font-size: 0.8em;
      }

      .details {
        rect {
          fill: none;
          stroke: #00aab2;
        }
      }
    }

    .bar-chart {
      position: relative;
      .bar-group {
        rect {
          cursor: pointer;
          stroke: $highlight-font-color;
          fill: rgba(136, 176, 75, 0.65);

          &:hover {
            fill: $highlight-font-color;
          }
        }
      }
    }
  }
}
