#privacy-policy {
    margin-bottom: 1em;
    padding: 2em;
    padding-top: 1em;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    color: #6c6c6c;
    background-color: white;
    overflow: auto;
    height: 100%;

    .logo {
        height: 50px;
    }
    
    .left {
        padding-top: 1em;

        a {
            color: $primary-color;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    h1, h2 {
        color: #2f2f2f;
        margin-top: 10px;
    }

}