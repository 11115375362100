
$page-nav-hover: #616161;

.pagination {
	.pagination-section {
		color: rgba($color: #F7F8FC, $alpha: .9);
		padding: 30px 20px;
		align-items: center;

		input {
			width: 4.1rem;
			margin: 0 .8rem;
			font: inherit;
		}

		.set-btn {
			width: 4.2em;
			margin: 0 0.8em;
		}

		&:last-child {
			justify-content: flex-end;
		}

		.page-navigation {
			display: table;
			width: 80px;
			height: 2.5rem;
			margin-left: 20px;
			background-color: $yellow-color;
			overflow: hidden;

			& > div {
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				width: 50%;
				transition: background-color .2s;

				&:hover {
					background-color: $page-nav-hover;
				}

				i {
					font-size: 1.4rem;
					font-weight: bold;
				}

				&.disabled {
					background-color: lighten($secondary-color, 18%);
					cursor: default;
				}
			}
		}
	}
}