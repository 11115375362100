._hBkdu {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
._1LTnF {
	border: none;
	outline: none;
	font-size: 0.9em;
	height: 2.5em;
	padding: 0.2em 1.2em 0.2em 0.5em;
	border-radius: 0.417em;
	background-color: #f7f7f7;
	color: #6c6b6b;
}
._jemnL {
	position: absolute;
	z-index: 2;
	left: 50%;
	transform: translate(-50%, 0);
}
._1cqqD {
	margin: 0 auto;
	display: inline-block;
	margin-top: 1px;
	box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-webkit-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
}

._vx6sj {
	box-sizing: border-box;
	margin: 0 auto;
	font-family: Geneva, Tahoma, Verdana, sans-serif;

	/** clock size */
	--clock-size: 16em;
	--clock-padding: 0.8em;
	--clock-hand-height: 1.8em;

	padding-top: 0.8em;
	width: calc(var(--clock-size) + (var(--clock-padding) * 2));
}

._3biIY {
	text-align: center;
	font-size: 1.5em;
	padding-bottom: 0.4em;
}

._34oDc {
	position: relative;
	padding: var(--clock-padding);
}

._3oKLI {
	height: var(--clock-size);
	width: var(--clock-size);
	border-radius: 50%;
}

._2iv3M {
	position: absolute;
	top: calc((var(--clock-size) / 2));
	width: calc(var(--clock-size) / 2);
	height: var(--clock-hand-height);
	transform-origin: left;
}

._1o7MO {
	position: relative;
	z-index: 2;
	cursor: pointer;
	width: var(--clock-hand-height);
	height: var(--clock-hand-height);
	line-height: var(--clock-hand-height);
	text-align: center;
	display: inline-block;
	float: right;
	margin-left: 0.5em;
	margin-right: 0.4em;
	border-radius: 50%;
	transition: all 0.2s linear;
	transform: scale(1) rotate(var(--font-rotation));
}

._3O0bw {
}

._3BMV5 {
}

._3O0bw:hover,
._3BMV5:hover {
	background: var(--hover-bg-color) !important;
	color: var(--hover-color) !important;
	transform: scale(1.2) rotate(var(--font-rotation));
}

._1GirA {
	z-index: 1;
	pointer-events: none;
	position: absolute;
	height: 1px;
	transform-origin: left;
	transition: all 0.2s linear;
}

._1z6mA {
	width: calc((var(--clock-size) / 2) - var(--clock-hand-height));
}

._21S3B {
	width: calc((var(--clock-size) / 2) - (var(--clock-hand-height) * 2.5));
}

._3UUd6 {
	cursor: pointer;
	position: absolute;
	bottom: 0.4em;
	height: 2.5em;
	line-height: 2.5em;
	width: 2.5em;
	text-align: center;
	border-radius: 50%;
	transition: all 0.2s linear;
}

._278UM {
	left: 0.4em;
}

._2os-v {
	right: 0.4em;
}

._3UUd6:hover {
	color: var(--hover-color) !important;
	background: var(--hover-bg-color) !important;
}

._r21F9 {
	position: relative;
}

._1cU2- {
	/* 1.7 is padding in input */
	width: calc(100% - 1.7em);
	text-align: center;
}

._hBkdu {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

._1LTnF {
	border: none;
	outline: none;
	font-size: 0.9em;
	height: 2.5em;
	padding: 0.2em 1.2em 0.2em 0.5em;
	border-radius: 0.417em;
	background-color: #f7f7f7;
	color: #6c6b6b;
}

._jemnL {
	position: absolute;
	z-index: 2;
	left: 50%;
	transform: translate(-50%, 0);
}

._1cqqD {
	margin: 0 auto;
	display: inline-block;
	margin-top: 1px;
	box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-webkit-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
}
._hBkdu {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
._1LTnF {
	border: none;
	outline: none;
	font-size: 0.9em;
	height: 2.5em;
	padding: 0.2em 1.2em 0.2em 0.5em;
	border-radius: 0.417em;
	background-color: #f7f7f7;
	color: #6c6b6b;
}
._jemnL {
	position: absolute;
	z-index: 2;
	left: 50%;
	transform: translate(-50%, 0);
}
._1cqqD {
	margin: 0 auto;
	display: inline-block;
	margin-top: 1px;
	box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-webkit-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
}

._2mSeY {
	box-sizing: border-box;
	margin: 0 auto;
	font-family: Geneva, Tahoma, Verdana, sans-serif;

	/* other element sizes */
	--month-pill-size: 4em;
	--month-pill-margin: 0.3em;

	padding-top: 0.8em;
	width: calc((var(--month-pill-size) * 4) + (var(--month-pill-margin) * 9));
}

/* --------- Year Picker ------------ */

._2gS5K {
	font-size: 1.5em;
	text-align: center;
	padding-bottom: 0.4em;
}

._1EJAe {
	padding-bottom: 0.4em;
	text-align: center;
}

._1uUzV {
	display: inline-block;
	text-align: center;
	width: 6em;
}

._3VWd4 {
	cursor: pointer;
	padding: 0.5em;
	display: inline-block;
	transition: all 0.2s linear;
	border-radius: 0.5em;
	font-weight: bold;
	margin: 0 0.6em;
}

._3VWd4:hover {
	color: var(--year-edit-hover-color) !important;
	background: var(--year-edit-hover-bg-color);
}

/* --------- Month Picker ------------ */

._2r92N {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 18.7em;
}

._3C0Y9 {
	display: inline-block;
	padding: 0.1em;
	white-space: nowrap;
	overflow: hidden;
	width: calc((var(--month-pill-size) * 3) + (var(--month-pill-margin) * 6));
}

._2r8vx {
	transition: all 0.2s linear;
}

._3QNqV {
	display: inline-block;
	cursor: pointer;
	width: calc(var(--month-pill-size) / 2);
	border-radius: 0.8em;
	margin: var(--month-pill-margin);
	height: 1.2em;
	line-height: 1.2em;
	text-align: center;
}

._F37Gy {
	display: inline-block;
	cursor: pointer;
	padding: 0.3em 0;
	width: var(--month-pill-size);
	border-radius: 0.8em;
	margin: var(--month-pill-margin);
	line-height: 1.2em;
	text-align: center;
	transition: all 0.2s linear;
}
._hBkdu {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
._1LTnF {
	border: none;
	outline: none;
	font-size: 0.9em;
	height: 2.5em;
	padding: 0.2em 1.2em 0.2em 0.5em;
	border-radius: 0.417em;
	background-color: #f7f7f7;
	color: #6c6b6b;
}
._jemnL {
	position: absolute;
	z-index: 2;
	left: 50%;
	transform: translate(-50%, 0);
}
._1cqqD {
	margin: 0 auto;
	display: inline-block;
	margin-top: 1px;
	box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-webkit-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
}

._2bhIL {
	box-sizing: border-box;
	margin: 0 auto;
	font-family: Geneva, Tahoma, Verdana, sans-serif;

	/* other element sizes */
	--calender-pill-size: 3em;

	padding-bottom: 0.8em;
	width: calc((var(--calender-pill-size) * 7) + 1.6em);
}

._3u59v {
	margin: 0 auto;
	/* month may have 6 or 5 rows, keep calender hight same for both */
	min-height: 16em;
	border-collapse: collapse;
}

._2QWPf {
	text-align: center;
	font-weight: bold;
}

._S_9d2 {
	padding: 0.5em 1em;
}

._1bbEQ {
	text-align: center;
	cursor: pointer;
	font-weight: bold;
	padding: 0.5em 0.8em;
	transition: all 0.2s linear;
	/* transparent border so when hover adds 1 px border UI do not change size */
	border-bottom-color: transparent;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-top-color: transparent;
	border-top-style: solid;
	border-top-width: 1px;
}

._1bbEQ:hover {
	background: var(--calender-cell-hover-bg-color);
	color: var(--calender-cell-hover-color) !important;
}

._VsVA7 {
	position: relative;
}

._myLhn {
	/* 1.7 is padding in input */
	width: calc(100% - 1.7em);
	text-align: center;
}

._myLhn:disabled {
	background-color: #e9e9e9;
}

._hBkdu {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
._1LTnF {
	border: none;
	outline: none;
	font-size: 0.9em;
	height: 2.5em;
	padding: 0.2em 1.2em 0.2em 0.5em;
	border-radius: 0.417em;
	background-color: #f7f7f7;
	color: #6c6b6b;
}
._jemnL {
	position: absolute;
	z-index: 2;
	left: 50%;
	transform: translate(-50%, 0);
}
._1cqqD {
	margin: 0 auto;
	display: inline-block;
	margin-top: 1px;
	box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-webkit-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
}

._1Tn6F {
	box-sizing: border-box;
	margin: 0 auto;
	font-family: Geneva, Tahoma, Verdana, sans-serif;

	display: table;
}

._1V8Vy {
	display: table-cell;
	vertical-align: middle;
	padding: 0.8em;
}

._3lBj_ {
	padding-right: 1.6em;
}

._1V8Vy:last-child {
	padding-left: 1.6em;
}

._9PYZ9 {
	position: relative;
}

._1XGgG {
	/* 1.7 is padding in input */
	width: calc(100% - 1.7em);
	text-align: center;
	min-width: 13em;
}

._1XGgG:disabled {
	background-color: #e9e9e9;
}

._39RmR {
	position: relative;
}

._2ZMta {
	position: absolute;
	padding: 0.7em;
	right: 0;
	cursor: pointer;
}

._2ZMta:hover {
	text-decoration: underline;
}
._hBkdu {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
._1LTnF {
	border: none;
	outline: none;
	font-size: 0.9em;
	height: 2.5em;
	padding: 0.2em 1.2em 0.2em 0.5em;
	border-radius: 0.417em;
	background-color: #f7f7f7;
	color: #6c6b6b;
}
._jemnL {
	position: absolute;
	z-index: 2;
	left: 50%;
	transform: translate(-50%, 0);
}
._1cqqD {
	margin: 0 auto;
	display: inline-block;
	margin-top: 1px;
	box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-webkit-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
}

._ILtx4 {
	box-sizing: border-box;
	margin: 0 auto;
	font-family: Geneva, Tahoma, Verdana, sans-serif;
}

._2V-rv {
	display: table-row;
	text-align: left;
}

._EuJxx {
	display: inline-block;
	margin: 1em 1.5em;
	cursor: pointer;
}

._73iy2 {
	font-size: 0.86em;
	padding-left: 0.5em;
	font-weight: bold;
}

._3mb6c {
	font-size: 1em;
	min-height: 2em;
	line-height: 2em;
	padding: 0em 1em;
	border-radius: 0.8em;
	margin-top: 0.5em;
	transition: all 0.2s linear;
	min-width: 19em;
	text-align: center;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
	-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
}

._G09ZR {
	background: #4dc4ca;
	color: var(--primary-color);
}

._jrDzm {
	font-weight: bold;
	background: var(--primary-color);
	color: var(--secondary-highlight-color);
}

._2SWvP {
	float: right;
	margin: 1.7em 1.5em;
	padding: 0.4em 0.8em;
	border-radius: 0.3em;
	cursor: pointer;
	font-weight: bold;
	transition: all 0.2s linear;
	min-height: 2em;
	line-height: 2em;
}

._2SWvP:hover {
	text-decoration: underline;
}

._1A0Hu {
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	min-width: 10em;
	height: 27em;
	/* min height not work on td; */
}
._3hmCg {
	cursor: pointer;
	color: #ffffffbd;
	font-weight: bold;
	padding: 1em 1em 0.8em 1em;
	border-left: 0.5em solid transparent;
	transition: all 0.2s linear;
	min-width: 8em;
	position: relative;
}

._3hmCg::after {
	content: "";
	position: absolute;
	left: -0.5em;
	right: 0;
	bottom: 0;
	height: 1px;
	background: var(--active-pill-hover-color);
	opacity: 0.28;
}

._3hmCg:last-child::after {
	height: 0px;
}

._2gAox {
	border-left: 0.5em solid;
}

._3hmCg:hover {
	color: var(--active-pill-hover-color);
	border-left: 0.5em solid;
}

._2PVHM {
	display: table;
	margin: 0 auto;
}

._3S0Kn {
	position: relative;
}

._1KtLs {
	/* 1.7 is padding in input */
	width: calc(100% - 1.7em);
	text-align: center;
	min-width: 30em;
}

/**
Date range picker style
**/

._3mDRm {
	display: table-cell;
	vertical-align: middle;
	padding: 0.8em;
}

._1W7Sv {
	padding-left: 0;
	padding-right: 0;
	position: relative;
}

._1W7Sv::before {
	position: absolute;
	content: "";
	height: calc(50% - 1.6em);
	border-right: 1px solid;
	top: 0;
}

._1W7Sv::after {
	position: absolute;
	content: "";
	height: calc(50% - 1.6em);
	border-right: 1px solid;
	bottom: 0;
}

._CIfin {
	height: 2em;
	width: 2em;
	border: 1px solid;
	border-radius: 50%;
	font-size: 1.2em;
}

._1a4yJ {
	text-align: center;
	line-height: 2em;
}

._2t3OK {
	padding-left: 0;
}

._1xLCZ {
	padding-right: 0;
}
