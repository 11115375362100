$header-font-color: #FAFAFA;
$col-font-color: #616161;
$col-padding: 8px;
$page-nav-hover: #616161;


.list-container {
	display: table;
	width: 100%;
	border-spacing: 0px 2px;
	position: relative;
	z-index: 1; // for watermark avoid

	&.fix-table {
		table-layout: fixed;
	}

	.list-row {
		display: table-row;
		vertical-align: middle;
		background-color: $secondary-light-color;
		transition: background-color .2s;

		&:first-child {
			background-color: $orange-color;

			.list-col {
				color: $header-font-color;
			}
		}

		&:hover:not(:first-child) {
			background-color: darken($color: $secondary-light-color, $amount: 6%);
		}

		.list-col {
			display: table-cell;
			color: $col-font-color;
			font-weight: bold;
			text-align: center;
			padding: $col-padding;
			vertical-align: middle;
			// min height is not working on table tr td
			// height is consider as min height, if content more then 50, it grows
			height: 32px; // to show loader effect

			&.action-col {
				width: 8.5em;

				&.pd-l-0 {
					padding-left: 0px;
				}

				.action-btn {
					margin: 0 auto;
					width: 8.5em;
					padding: 0.4em 0.8em;
					transition: all 0.2s;

					&:hover {
						background-color: $yellow-color;
						-webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.4);
						-moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.4);
						box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.4);
					}

					i {
						margin-right: 0.5em;
					}
				}

			}

			.list-img {
				height: 30px;
				max-width: 30px;
				object-fit: cover;
			}
		}
	}

	.list-empty-wrapper {
		position: absolute;
		background-color: white;
		width: 100%;
		font-size: $title;
		color: $secondary-color;
		font-weight: bold;
		text-align: center;
		padding: 3em 1em;
		margin-bottom: 4em;

		&.error {
			color: $red-color;
		}
	}
}
