@import './constant';
@import './layout';
@import './colors';

@import './forms/form';
@import './forms/list_element';
@import './forms/search_box';
@import './forms/checkbox';

@import './page_details';

@import './others/notification';
@import './others/analyticsSearch';
@import './others/listLoader';
@import './others/loadingButton';
@import './others/navbar';
@import './others/pagination';
@import './others/waterMark';
@import './others/authWrapper';
@import './others/dashboardPage';
@import './others/loader';
@import './others/react_super_picker';
@import './others/time_selection';

@import './profile/changePassword';
@import './profile/profilePage';

@import './reports/report_page';
@import './reports/report_ag_table';
@import './reports/report_form';
@import './reports/report_vehicle_refule';

@import './trip/trips_add';
@import './trip/trip_list';

@import './others/privacy_policy';

* {
	box-sizing: border-box;
}

html, body {
	font-family: Tahoma,Verdana,Segoe,sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 12px;
	height: 100%;
	margin: 0px;
	padding: 0px;
}

#root {
	background: $primary-color;
	height: 100%;
}

a, a:link, a:visited, a:focus, a:hover, a:active {
	text-decoration : none;
}

/*******************
  Utility Classes
*******************/

.flex-wrapper {
	display: flex;

	&.justify-center {
		justify-content: center;
	}

	&.align-center {
		align-items: center;
	}

	.flex-lg {flex: 3;}
	.flex-md {flex: 2;}
	.flex-sm {flex: 1;}
}

.table-wrapper {
	display: table;

	.table-row {
		display: table-row;
	}

	.table-col {
		display: table-cell;

		&.mid {
			vertical-align: middle;
		}
	}
}
  
// .error-block {
// 	text-align: center;
// 	color: #f96159;
// }

.error-block {
	color: #a94442;
	font-size: .8em;
	text-align: center;
	padding: 0.8em;
}

.hide {
  	display: none !important;
}

.left-align {
  	text-align: left;
}

.right-align {
  	text-align: right;
}

.center, .center-align {
  	text-align: center;
}

.left {
  	float: left !important;
}

.right {
  	float: right !important;
}

img.responsive-img,
video.responsive-video {
	max-width: 100%;
	height: auto;
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.no-select, input[type=range],
input[type=range] + .thumb {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.circle {
  	border-radius: 50%;
}

.truncate {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.no-padding {
  	padding: 0 !important;
}

.no-margin {
  	margin: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.clearfix {
  	clear: both;
}

.scrollable {
  	overflow-y: auto;
}

.clickable {
  	cursor: pointer;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}


.z-depth-0 {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-floating, .dropdown-content, .collapsible, .side-nav {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.z-depth-1-half, .btn:hover, .btn-large:hover, .btn-floating:hover {
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.z-depth-2 {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
          box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

.z-depth-3 {
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
          box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}

.z-depth-4, .modal {
  -webkit-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
          box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}

.z-depth-5 {
  -webkit-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}


.scale-transition {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
	transition: -webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
	transition: transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
	transition: transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63), -webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
}

.scale-transition.scale-out {
	-webkit-transform: scale(0);
			transform: scale(0);
	-webkit-transition: -webkit-transform .2s !important;
	transition: -webkit-transform .2s !important;
	transition: transform .2s !important;
	transition: transform .2s, -webkit-transform .2s !important;
}

.scale-transition.scale-in {
	-webkit-transform: scale(1);
			transform: scale(1);
}