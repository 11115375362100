$form-error-color: #c51d1d;
$input-font: 1.1em;
$input-height: 36px;

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

.form-wrapper {
  width: 370px;
  background-color: white;
  margin: 0px auto;

  .form-fields {
    padding: 16px;
  }

  .action-btns {
    padding: 16px;
    text-align: center;
  }
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

// apply text input style
.textbox,
.super-picker-wrapper > input {
  outline: none;
  border: none;
  padding: 0.6em 0.8em;
  width: 100%;
  font-size: $input-font;
  height: $input-height;
  background-color: #fff;
  @include placeholder(rgba($color: $primary-color, $alpha: 0.4));
  color: rgba($color: $primary-color, $alpha: 0.9);
}

.super-picker-wrapper > input {
  background-color: #fff !important;
}

// override picker properties
.super-picker-wrapper > input {
  border-radius: 0;
  min-width: 100%;
}

.super-picker-wrapper .super-picker-popup {
  left: 0;
  transform: translate(0, 0);
}

textarea {
  resize: none;
  height: 6em !important;
}

.super-picker > input {
  outline: none;
  border: none;
  padding: 6px 8px;
  width: 100%;
  height: 2.5rem;
  background-color: #fff;
  text-align: left;
  font-size: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0;
}

// this class represent fomr wrapper

.form-section {
  width: 44%;
  max-width: 430px;
  z-index: 1;
  position: relative;
  background-color: $secondary-light-color;
  margin: 30px auto;
  padding: 20px 0;

  .form-section-title {
    font-size: $sub-title;
    color: $primary-color;
    font-weight: bold;
    text-align: center;
    padding: 0.6em;

    .sub-title {
      font-size: .7em;
    }
  }

  .form-field {
    margin: 1rem 3rem;

    .form-label {
      display: table;
      width: 98%;

      .label {
        width: auto;
        display: table-cell;
        vertical-align: bottom;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-weight: bold;
        font-size: 0.9rem;
        color: rgba($color: $primary-color, $alpha: 0.9);
      }

      .error {
        width: auto;
        display: table-cell;
        text-align: right;
        vertical-align: bottom;
        font-size: 0.8rem;
        padding: 0.4rem;
        background-color: rgba($color: $form-error-color, $alpha: 0.7);
        color: #fff;
      }
    }

    .select-dropdown {
      & > div {
        border: none;
        box-shadow: none;
        background-color: #fff;
        border-radius: 0px;
        min-height: $input-height;

        [class*="-placeholder"] {
          color: rgba($color: $primary-color, $alpha: 0.4);
          font-size: 0.9555em;
        }

        [class*="-singleValue"] {
          color: rgba($color: $primary-color, $alpha: 0.9);
          font-size: 0.9555em;
        }
      }

      [class*="-menu"] {
        top: 2.3em;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      }
    }

    .textbox {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  // show input 2 side by side
  .form-column {
    margin: 1rem 3rem;

    .form-column-field {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      margin: 0;

      &:first-child {
        padding-right: 0.6em;
      }

      &:last-child {
        padding-left: 0.6em;
      }

      .upload-block {
        width: 100%;
        height: 10em;
        position: relative;
        margin-right: 0.6em;
        background-color: darken($color: $secondary-light-color, $amount: 10%);
        border-radius: 0.2em;

        input {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          opacity: 0;
          cursor: pointer;

          &:hover {
            & + .upload-wrapper {
              background-color: darken($color: $secondary-light-color, $amount: 14%);
            }
          }
        }

        .upload-wrapper {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 2em 2em 0 2em;
          cursor: pointer;
          border-radius: 0.2em;
          z-index: 0;
          background-color: darken($color: $secondary-light-color, $amount: 10%);
          transition: background-color 0.2s;

          svg {
            height: 3em;
            fill: lighten($color: $secondary-color, $amount: 5%);
          }

          .upload-text {
            color: lighten($color: $secondary-color, $amount: 5%);
            font-size: 1.2em;
            font-weight: 400;
          }
        }

        .responsive-img {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          height: 100%;
          min-width: 100%;
          object-fit: cover;
          border-radius: 0.2em;
        }

        .remove-image {
          position: absolute;
          right: -0.7em;
          top: -0.7em;
          z-index: 2;
          height: 2em;
          width: 2em;
          cursor: pointer;
          border-radius: 0.1em;
          background-color: $secondary-light-color;
          transition: background-color 0.2s;

          &:hover {
            background-color: darken($color: $secondary-light-color, $amount: 10%);
          }

          svg {
            fill: $orange-color;
          }
        }
      }

      .form-field {
        margin: 1rem 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .form-actions {
    margin: 0 3rem;

    &.single-action {
      text-align: end;

      div {
        width: auto;
        padding: 0.8em 1em;
        display: inline-block;
      }
    }

    &.multi-action {
      text-align: end;

      .btn {
        width: auto;
        min-width: 8em;
        padding: 0.8em 1em;
        display: inline-block;
        margin-left: 1.4em;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
