$selector-font: 0.92em;
$selector-height: 2.8em;
$selector-width: 6.6em;

.time-selection-wrapper {
    position: relative;

    .time-selection-tabs {
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;

        .selection-tab {
            display: inline-block;
            vertical-align: middle;
            color: white;
			background-color: $orange-color;
            font-weight: bold;
            font-size: $selector-font;
            height: $selector-height;
            line-height: $selector-height;
            min-width: $selector-width;
            text-align: center;
            transition: all 0.2s linear;
            cursor: pointer;
            
            &:last-child {
                border-right: none;
            }

            &.active {
                color: $orange-color;
				background-color: white;

                &:hover {
                    box-shadow: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                }
            }

            // &:hover {
            //     box-shadow: 0px 0px 3px 1px $border-color inset;
            //     -webkit-box-shadow: 0px 0px 3px 1px $border-color inset;
            //     -moz-box-shadow: 0px 0px 3px 1px $border-color inset;
            // }
        }
    }

    .ts-datetime-picker {
        position: absolute;
        border-radius: 10px;
        box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.4);
        z-index: 3; // required for show over overlay

        .ts-datetime-picker-wrapper {
            position: relative;

            .ts-datetime-picker-close {
                position: absolute;
                right: 0.6em;
                top: 0.6em;
                cursor: pointer;
                text-decoration: underline;
                padding: 0.4em;
                color: rgb(136, 176, 75);
            }
        }

        .ts-datetime-picker-popup {
            box-shadow: -4px 4px 44px -4px rgba(0, 0, 0, 0.24);
        }

        & + .overlay {
            cursor: default;
            position: fixed;
            top: 0px;
            bottom: 0px;
            right: 0px;
            left: 0px;
            z-index: 2; // overlay on top
        }
    }
}