$page-title-height: 50px;
$page-header-height: 80px;

#main-container {
	padding-left: $sidebar-width;
	height: 100%;

	.page-container {
		height: 100%;

		&.small-page-title {
			.page-title {
				font-size: $sub-title;
				height: $page-title-height;
			}
			.page-content {
				height: calc(100% - #{$page-title-height});
			}
		}

		.page-title {
			font-size: 2rem;
			font-weight: bold;
			height: 100px;
			padding-left: 0.8em;
			color: #fff;
			display: flex;
			align-items: center;

			.heading {
				user-select: none;
			}

			.back-icon {
				fill: #fff;
				height: 1.3em;
				cursor: pointer;
			}
		}

		.page-title-wrapper {
			position: relative;
		}

		.page-content {
			height: calc(100% - 100px);
			overflow: hidden;

			.data-sections {
				width: 90%;
				margin: 0 auto;
				z-index: 1;
				position: relative;

				.list-heading {
					padding-top: 2em;
					text-align: center;
					color: #fff;
					font-size: 1.8em;
					font-weight: bold;
				}

				// use if heading on list page. currently use in dashboard
				.below-heading {
					padding-top: 0em !important;
					margin-top: -1.1em;
				}

				.search-result-section {
					padding: 24px;
					font-size: 0.8rem;
					color: rgba($color: #fff, $alpha: 0.6);
					padding-left: 30px;
					padding-top: 40px;
				}
			}
		}
	}
}

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// Grid
$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width * 2)/3 !default;

.flex-wrapper {
	display: flex;

	.flex-lg {
		flex: 3;
	}
	.flex-md {
		flex: 2;
	}
	.flex-sm {
		flex: 1;
	}
}

.container {
	margin: 0 auto;
	width: 85%; // showign best in 1500px screen
}

.container-fluid {
	margin: 0 auto;
	width: 100%;
}

.col .row {
	margin-left: (-1 * $gutter-width / 2);
	margin-right: (-1 * $gutter-width / 2);
}

.section {
	padding-top: 1rem;
	padding-bottom: 1rem;

	&.no-pad {
		padding: 0;
	}
	&.no-pad-bot {
		padding-bottom: 0;
	}
	&.no-pad-top {
		padding-top: 0;
	}
}

// Mixins to eliminate code repitition
@mixin reset-offset {
	margin-left: auto;
	left: auto;
	right: auto;
}
@mixin grid-classes($size, $i, $perc) {
	&.offset-#{$size}#{$i} {
		margin-left: $perc;
	}
	&.pull-#{$size}#{$i} {
		right: $perc;
	}
	&.push-#{$size}#{$i} {
		left: $perc;
	}
}

.row {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;

	// Clear floating children
	&:after {
		content: "";
		display: table;
		clear: both;
	}

	.col {
		float: left;
		box-sizing: border-box;
		padding: 0 $gutter-width / 2;
		min-height: 1px;

		&[class*="push-"],
		&[class*="pull-"] {
			position: relative;
		}

		$i: 1;
		@while $i <= $num-cols {
			$perc: unquote((100 / ($num-cols / $i)) + "%");
			&.s#{$i} {
				width: $perc;
				@include reset-offset;
			}
			$i: $i + 1;
		}

		$i: 1;
		@while $i <= $num-cols {
			$perc: unquote((100 / ($num-cols / $i)) + "%");
			@include grid-classes("s", $i, $perc);
			$i: $i + 1;
		}

		@media #{$medium-and-up} {
			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				&.m#{$i} {
					width: $perc;
					@include reset-offset;
				}
				$i: $i + 1;
			}

			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				@include grid-classes("m", $i, $perc);
				$i: $i + 1;
			}
		}

		@media #{$large-and-up} {
			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				&.l#{$i} {
					width: $perc;
					@include reset-offset;
				}
				$i: $i + 1;
			}

			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				@include grid-classes("l", $i, $perc);
				$i: $i + 1;
			}
		}

		@media #{$extra-large-and-up} {
			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				&.xl#{$i} {
					width: $perc;
					@include reset-offset;
				}
				$i: $i + 1;
			}

			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				@include grid-classes("xl", $i, $perc);
				$i: $i + 1;
			}
		}
	}
}

/*********************
  Media Query Classes
**********************/
.hide-on-small-only,
.hide-on-small-and-down {
	@media #{$small-and-down} {
		display: none !important;
	}
}
.hide-on-med-and-down {
	@media #{$medium-and-down} {
		display: none !important;
	}
}
.hide-on-med-and-up {
	@media #{$medium-and-up} {
		display: none !important;
	}
}
.hide-on-med-only {
	@media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
		display: none !important;
	}
}
.hide-on-large-only {
	@media #{$large-and-up} {
		display: none !important;
	}
}
.hide-on-extra-large-only {
	@media #{$extra-large-and-up} {
		display: none !important;
	}
}
.show-on-extra-large {
	@media #{$extra-large-and-up} {
		display: block !important;
	}
}
.show-on-large {
	@media #{$large-and-up} {
		display: block !important;
	}
}
.show-on-medium {
	@media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
		display: block !important;
	}
}
.show-on-small {
	@media #{$small-and-down} {
		display: block !important;
	}
}
.show-on-medium-and-up {
	@media #{$medium-and-up} {
		display: block !important;
	}
}
.show-on-medium-and-down {
	@media #{$medium-and-down} {
		display: block !important;
	}
}
