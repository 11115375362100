#report-container {
  .report-form-wrapper {
    width: 100%;
    padding: 2em;
    background-color: #fff;

    .checkbox-group {
      .form-field {
        display: inline-block;
        vertical-align: top;
        min-width: 13em;
      }
    }

    .label {
      padding-bottom: 0.4rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-weight: bold;
      font-size: 0.9rem;
      color: rgba($color: $primary-color, $alpha: 0.9);
    }

    .range-picker {
      & > input {
        outline: none;
        border: none;
        padding: 6px 8px;
        min-height: 38px;
        font-size: inherit;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 0;
      }

      .range-picker-popup {
        left: 0;
        transform: translate(0, 0);
      }
    }

    .selection-wrapper {
      max-width: 600px;
      padding: 1em 0;

      .multi-select-wrapper {
        .action-btn {
          display: inline-block;
          margin-right: 1em;
          margin-bottom: 1em;
          width: auto;
        }
      }
    }

    .action-wrapper {
      border-top: 1px solid $secondary-light-color;
      padding-top: 2em;

      &::after {
        clear: both;
        display: table;
        content: "";
      }

      .action-btn {
        width: 20%;
        max-width: 150px;
      }
    }

    .mr-b-1 {
      margin-bottom: 1em !important;
    }
  }
}
