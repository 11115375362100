.search-wrapper {
	display: flex;
	width: 100%;
    margin-left: auto;
	max-width: 738px;
	position: absolute;
	z-index: 2;
	top: 82px;
	right: 3.6%;

	.search-input-container {
		position: relative;
		background-color: #fff;
		flex: 1;
		display: flex;
		align-items: center;
		height: 2.6em;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.36);
	
		.icn {
			height: 1.8em;
			width: 1.9em;
			line-height: 1.5em;
			text-align: center;
			font-size: 1.2em;
			color: $primary-color;

			.fa {
				font-size: 0.9em;
			}

			&.clear-text {
				background-color: $red-soft-color;
				color: $red-color;
				border: 1px solid $red-color;
				transition: all .2s linear;
				cursor: pointer;
				margin-right: 0.3em;

				&:hover {
					background-color: darken($color: $red-soft-color, $amount: 5%);
				}
			}

			&.magnifier {
				background-color: $green-soft-color;
				color: $green-color;
				border: 1px solid $green-color;
				transition: all .2s linear;
				cursor: pointer;
				margin-right: 0.3em;

				&:hover {
					background-color: darken($color: $green-soft-color, $amount: 5%);
				}
			}
		}

		.mr-3 {
			margin-right: 0.3em;
		}

		.ml-3 {
			margin-left: 0.3em;
		}
	
		.search-input {
			border-radius: 0;
			padding: 0;
			height: 100%;
			padding-left: 1.2em;
		}

		.tab-selector-wrapper {
			position: absolute;
			bottom: 2.6em;
			height: 2em;
			line-height: 2em;

			.tab-selector {
				cursor: pointer;
				display: inline-block;
				
				padding: 0 1em;
				font-size: 1.2em;
				color: white;
				background-color: $orange-color;
				transition: all .2s linear;
				box-shadow: inset 1px 0px 1px -1px rgba(0, 0, 0, 0.75);

				&.active, &:hover {
					color: $orange-color;
					background-color: white;
				}

				&.left-selector {
					left:10em;
					// border-left: 1px solid white
				}
			}
		}
	}

	.btn-container {
		padding-left: 10px;
	}
}