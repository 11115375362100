.secondary-bg {
	background-color: $secondary-color;
}

.secondary-light-bg {
	background-color: $secondary-light-color;
}

.text-white {
	color: #fff;
}

.bg-white {
	background-color: white;
}

.text-black {
	color: #000;
}

.orange-bg-color {
	background-color: $orange-color;
}

.yellow-bg-color {
	background-color: $yellow-color;
}

.text-orange {
	color: $orange-color;
}

.text-yellow {
	color: $yellow-color;
}

.text-red {
	color: $red-color;
}

.text-green {
	color: $green-color;
}

.text-secondary-dark {
  color: $secondary-dark-color;
}