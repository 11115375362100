$page-title-height: 50px;
$page-header-height: 60px;

#trip-add {
    // hide watermark
    & + .watermark.vector {
        z-index: -1;
    }

    .page-title {
        font-size: $sub-title !important;
        height: $page-title-height !important;
    }

    .page-content {
        height: calc(100% - #{$page-title-height}) !important;

        .header-action-wrapper {
            height: $page-header-height;
            display: flex;
            background: #454545;

            .header-trip-title {
                flex: 0 0 300px;
                align-self: center;
                color: #ccc;
                font-weight: 700;
                padding: 0 1.2em;
                font-size: 1.2em;
            }

            .header-actions {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 1.3em;

                .btn {
                    width: auto;
                    cursor: pointer;
                }
            }
        }

        .schedules-page-wrapper {
            display: flex;
            height: calc(100% - #{$page-header-height}); // header height minus

            .trip-list-col {
                flex: 0 0 300px;
                overflow: auto;
                padding-bottom: 2em;
                background-color: $secondary-dark-color;

                .trip-vehicle-container {
                    color: #a9a9a9;

                    .trip-vehicle-item {
                        display: table;
                        width: 100%;
                        padding-left: 8%;
                        padding-right: 6px;
                        cursor: pointer;
                        border-right: 2px solid transparent;
                        transition: all .2s linear;
                        min-height: 4em;

                        .text {
                            display: table-cell;
                            vertical-align: middle;
                            width: 90%;
                            padding: 10px 12px 10px 4px;
                            border-bottom: 1px solid;

                            .sub-text {
                                font-size: 0.9em;
                                line-height: 1.2em;
                            }
                        }

                        .badge-col {
                            display: table-cell;
                            vertical-align: middle;
                            border-bottom: 1px solid;
                            width: 10%;
                            
                            .badge {
                                border-radius: 50%;
                                background-color: $primary-color;
                                color: white;
                                text-align: center;
                                font-size: 0.88em;
                                height: 1.5em;
                                line-height: 1.5em;
                                width: fit-content;
                                padding: 0 5px;
                            }
                        }

                        &:last-child {
                            .text, .badge-col {
                                border-bottom: none;
                            }
                        }

                        &:hover,
                        &.active {
                            background-color: darken($color: $secondary-dark-color, $amount: 6%);
                            border-right: 2px solid $yellow-color;
                        }
                    }
                }
            }

            .schedules-container-col {
                flex: 1;
                overflow: auto;
                padding-bottom: 4em;
                
                .form-section {
                    width: 80%;
                    max-width: 900px;
                    position: unset;
                    margin: 10px auto;

                    textarea {
                        min-height: 7.68em;
                    }

                    .form-submit-btn {
                        float: right;
                        margin-right: 3rem;

                        .btn {
                            min-width: 11em;
                        }
                    }
                }

                .message-wrapper {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;

                    .message {
                        font-size: 1.6em;
                        font-weight: bold;
                        color: $secondary-light-color;

                        span {
                            color: $yellow-color;
                        }
                    }
                }
            }
        }
    }
}
