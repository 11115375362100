$size: 18px;
$inactive-color: #6c6b6b;

.checkbox-container {
    display: table;
    cursor: pointer;
    padding: 4px;

    &.active {
        .checkbox-label, .check {
            color: $orange-color;
        }
    }

    .checkbox-label {
        display: table-cell;
        vertical-align: middle;
        font-size: 1em;
        padding-left: 6px;
        color: $inactive-color;
        transition: color .2s;
        font-weight: bold;
        line-height: 1.1;
    }

    .check {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: $size;
        color: $inactive-color;
        transition: color .2s;

        .fa {
            font-size: $size;
            vertical-align: middle;
        }
    }
}