.watermark {
	
	&.vector {
		bottom: 0px;
		right: 2%;
		height: auto;
		width: 185px;
		z-index: 0;

		svg {
			fill: rgba($color: #f8f8f8, $alpha: .2);
		}
	}
}