
$col-padding: 0.6em;
$min-height: 3em;

.page-details-wrapper {
	padding: 3em 3.6em;
	max-width: $max-container-width;
	margin: 0 auto;
	
	.basic-section {
		margin-bottom: 3.6rem;

		.table-wrapper {
			width: 100%;

			.table-col {
				vertical-align: top;
			}
		}

		.image-col {
			width: 12em;
		}

		.image-wrapper {
			width: 10em;
			height: 10em;
			border: .2em solid #fff;
			border-radius: .3em;

			img {
				max-width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.default-driver-icon {
				width: 100%;
				height: 100%;
				padding: 6%;
			}
		}

		.content {

			.heading {
				font-size: $heading;
				font-weight: bold;
				margin: .4rem 0;
			}

			.title {
				font-size: $title;
				font-weight: bold;
				color: $grey-color-1;
				margin-bottom: .32em;
			}

			.sub-title {
				font-size: $sub-title;
				color: $grey-color-1;
				margin-bottom: .32em;
			}

			.mid-text {
				font-size: $mid-text;
				color: $grey-color-1;
				margin-bottom: 0.4rem;
			}

			.top-pad {
				padding-top: 0.3em;
			}

			.external-link-icon {
				margin-left: 0.5em;
			}

			.text-hover-underline:hover {
				text-decoration: underline;
			}

		}
	}

	.page-action-section {
		width: 30%;
		max-width: 180px;
		justify-content: flex-end;

		.action-block {
			height: 84px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0.3em;
			cursor: pointer;
			transition: box-shadow .2s;

			.action-text {
				color: #fff;
				font-weight: bold;
				font-size: 1.4em;
			}

			&:hover {
				-webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
          		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
			}

			&.mr-bottom {
				margin-bottom: 2em;
			}
 		}
	}

	.details-section {
		min-height: 30em;

		.details-cards-wrapper {
			&::after {
				clear: both;
				display: table;
				content: "";
			}
		}

		.details-card {
			float: left;
			background-color: #fff;
			padding: 0.8em 1.1em;
			margin-right: 1.4em;
			border-radius: 0.3em;
			min-width: 14em;
			cursor: pointer;
			position: relative;
			transition: all 0.2s;

			.normal-text {
				color: $secondary-color;
				transition: color 0.2s;
				padding-right: 2.4em;
			}

			.value {
				color: $secondary-dark-color;
				font-weight: bold;
				font-size: 2em;
				margin-top: .36rem;
				margin-bottom: .72rem;
				transition: color 0.2s;
			}

			.icon-wrapper {
				position: absolute;
				right: 1.1em;
				top: 0.8em;

				i {
					font-size: 2.2em;
					color: lighten($secondary-color, 30%);
					transition: color 0.2s;
				}
			}

			&::after {
				position: absolute;
				content: "";
				bottom: -0.9em;
				left: 0;
				right: 0;
				width: 0; 
				height: 0; 
				border-left: 1.1em solid transparent;
				border-right: 1.1em solid transparent;
				border-top: 1em solid $yellow-color;
				margin: 0 auto;
				transform: scaleY(0);
				transform-origin: top;
				transition: transform 0.2s;
			}


			&.active, &:hover {
				background-color: $yellow-color;
				box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.5);

				.normal-text, .value {
					color: #fff;
				}

				.icon-wrapper {
	
					i {
						color: $grey-color-4;
					}
				}

				&::after {
					transform: scaleY(1);
				}
			}
		}

		.list-container {
			width: 100%;
			border-spacing: 0px;
			margin-top: 1em;
			margin-bottom: 4em;

			.list-row {
				background-color: #fff;

				&.header-col {
					background-color: $secondary-dark-color;

					.list-col {
						color: #fff;
					}
				}

				.list-col {
					height: $min-height;
					text-align: center;
					padding: $col-padding;
					color: $secondary-dark-color;
					// width: 33.333%;
					border-bottom: 1px solid rgba(0, 0, 0, 0.25);
				}
			}

			&.details-trip-list {
				.status-col {
					width: 10%;
				}
				.action-col {
					width: 10%;
				}
			}
		}
	}
}