.auth-fixed-bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-image: url("/assets/img/loginbg-sm.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;

  @media only screen and (min-width: 992px) {
    background-image: url("/assets/img/loginbg-md.jpg");
  }

  @media only screen and (min-width: 1201px) {
    background-image: url("/assets/img/loginbg-lg.jpg");
  }

  @media only screen and (min-width: 2550px) {
    background-image: url("/assets/img/loginbg-xl.jpg");
  }
}

.auth-wrapper {
  min-height: 100%;
  position: relative;
}

.auth-body-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .auth-card {
    border-radius: 16px;
    background-color: #fff;
    max-width: 31rem;
    overflow: hidden;
    position: relative;
    padding: 4rem 0px;

    .privacy-policy-link {
      position: absolute;
      bottom: 0.4em;
      width: 100%;
      text-align: center;
      padding: 0.4em;

      a {
        font-size: 12px;
        color: $primary-color !important;
      }
    }
  }

  .store-icons {
    padding-top: 1.8em;

    .store-link {
      padding: 1em;

      img {
        width: 150px;

        &.store-google {
          width: 160px;
        }
      }
    }
  }

  .form-store-icons {
    padding-top: 2em;
    text-align: center;

    .store-link {
      width: 48%;
      display: inline-block;
      vertical-align: top;

      img {
        padding: 0.5em;
        width: 100%;

        &.store-google {
          width: 107.4%;
          padding-right: 1em;
        }
        &.store-apple {
          padding-left: 1em;
        }
      }
    }
  }

  .logo-wrapper {
    margin: 4rem 5rem;
    max-width: 21rem;

    img {
      width: 100%;
      object-fit: contain;
    }

    &.main-logo {
      font-size: 3em;
      font-weight: bold;
      text-align: center;
      margin: 3rem 1rem;
      max-width: 100%;
      color: $primary-color;
    }
  }

  .form-content-margin {
    padding: 0 4rem;
  }

  .title {
    font-size: 2rem;
    padding: 4px 0;
  }

  .bold {
    font-weight: 600;
  }

  .sub-title {
    font-weight: 1.1rem;
    padding: 4px 0;
  }

  .tiny-url {
    font-size: 0.8rem;
    text-align: right;

    a {
      color: inherit;
    }
  }

  .center {
    margin: 8px 0;
    text-align: center;
  }

  .accent-text {
    color: $primary-color;
  }

  // .success-text{
  // 	color:$green;
  // }

  .auth-input {
    width: 100%;
    padding: 16px 16px;
    font-size: 1rem;
    border-radius: 24px;
    outline: none;
    border: 0;
    background-color: #fafafa;
    color: $primary-color;
    box-shadow: 0px 2px 4px 0px rgba(88, 88, 88, 0.3);
  }

  .wrapper {
    margin: 16px 0;
  }

  .text-link {
    font-size: 0.8rem;
    cursor: pointer;

    &.light {
      color: darken(#73c2fb, 5);
    }
  }

  .btn-wrapper {
    padding: 0.5rem 2rem;

    .primary-btn,
    .disable-btn {
      margin-top: 1.3rem;
      border-radius: 24px;
      padding: 0.9em 1em;
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: uppercase;
      box-shadow: 0px 1px 4px 1px rgba(35, 50, 54, 0.3);
    }
  }
}

.social-media-wrapper {
  background-color: white;
  padding: 1.2rem 4rem 1rem 4rem; //padding top adjustment as imageas are places instead of div
  text-align: center;
  box-shadow: 0px -20px 50px 0px rgba(232, 232, 232, 1);

  .social-btn-wrapper {
    max-width: 19.5rem;
    margin: 0 auto;
    cursor: pointer;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
