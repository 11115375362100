$primary-color : #3B3B3B; // dark grey

$secondary-color: #8B8B8B; // medium grey
$secondary-light-color: #EBEBEB; // light grey
$secondary-dark-color: #616161;

$orange-color: #FF845E;

$yellow-color: #FFBC00;
$yellow-color-1: #FFCB4F;

$grey-color-1: #d0d0d0;
$grey-color-2: #929292;
$grey-color-3: #ddd;
$grey-color-4: #fff4dc;
$grey-color-5: #f7f7f7;
$divider-color: #bababa; //light grey


$prime-font-color: rgba(0, 0, 0, 0.57);

$red-color: #e63757;
$red-soft-color: #fce7eb;

$green-color: #00d27a;
$green-soft-color: #e0faef;

$sidebar-width: 104px;

//////////////////////////////
//      Typography         //
////////////////////////////

$heading: 2.2em;
$title: 1.7em;
$sub-title: 1.5em;
$mid-text: 1.2em;


//////////////////////////////
//      Dimention          //
////////////////////////////

$max-container-width: 1500px