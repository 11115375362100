$success-color: #65aa33;
$info-color: #4488ab;
$warning-color: #efaa33;
$error-color: #ef6646;

.notification {
	position: fixed;
	top: 15%;
	right: 15px;
	width: 350px;
	z-index: 100;
	margin: 0;
	padding: 0;

	.content {
		position: relative;
		display: table;
		height: 100px;
		color: white;
		margin-bottom: 20px;
		box-shadow: 0px 5px 3px 2px rgba(0, 0, 0, 0.8);
		transition: box-shadow 0.2s linear;

		&.error {
			background: $error-color;
		}

		&.success {
			background: $success-color;
		}

		&.info {
			background: $info-color;
		}

		&.warning {
			background: $warning-color;
		}

		&:hover {
			box-shadow: 0px 10px 6px 5px rgba(0, 0, 0, 0.4)
		}

		.cancel {
			position: absolute;
			top: 0px;
			right: 0px;

			padding: 5px 5px 5px 8px;
			cursor: pointer;

			height: 28px;
			width: 30px;
			background: rgba(0, 0, 0, 0.4);

			.fa {
				font-size: 130%;
			}
		}

		.icon {
			display: table-cell;
			background: rgba(0, 0, 0, 0.48);
			width: 70px;
			vertical-align: middle;
			text-align: center;
		}

		.text {
			display: table-cell;
			width: 280px;
			vertical-align: middle;
			padding: 10px;
			font-size: 105%;
		}
	}
}

@media (max-width: 320px) {
	// break point for iphone 5/4 screen sizes
	.notification {
		width: 300px;

		.content .text {
			width: 230px;
		}
	}
}
