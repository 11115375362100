$prime-font-color: rgba(0, 0, 0, 0.57);

#report-container {
  .report-table {
    width: 100%;
    padding-top: 2em;

    .report-ag-grid-style {
      width: 100%;
      // height: 800px;
      border: 1px solid #e2e2e2;
      border-bottom: none;

      &.no-data {
        height: 120px;
        border-bottom: 1px solid #e2e2e2;
      }

      .ag-header-cell-label {
        justify-content: flex-start;
        color: $prime-font-color;
      }

      .ag-row-hover {
        .fw-column-bg {
          background-color: rgb(251, 249, 234) !important;
        }
      }

      .fw-column-bg {
        border-right: 1px solid #e2e2e2 !important;
        display: flex;
        align-items: center;
        color: #808185;
        white-space: normal !important;
        line-height: 1.4;

        &:nth-child(odd) {
          background-color: #fff;
        }

        &:nth-child(even) {
          background-color: #f1f1f1;
        }

        &:last-child {
          border-right: none !important;
        }
      }

      .ag-header-cell {
        padding: 0 10px;
      }

      .ag-react-container {
        display: flex;
        align-items: center;
      }
    }
  }
}
