
%btn {
	cursor: pointer;
	color: white;
	padding: 0.8em;
	text-align: center;
	display: block;
	width: 100%;
	font-size: 0.9rem;
	font-weight: bold;
	border: none;
	text-decoration: none;
	outline: 0;
	transition: background-color .2s;
	user-select: none;
}

.yellow-btn {
	@extend %btn;
	background-color: $yellow-color;
	
	&:hover {
		background-color: darken($color: $yellow-color, $amount: 2%);
	}

	i {
		margin-right: 12px;
	}

	// &.loading {
	// 	i {margin-right: 15px}
	// 	background-color: grey;
	// }
}

.orange-btn {
	@extend %btn;
	background-color: $orange-color;
	
	&:hover {
		background-color: darken($color: $orange-color, $amount: 2%);
	}

	i {
		margin-right: 12px;
	}
}


.red-btn {
	@extend %btn;
	background-color: $red-color;
	
	&:hover {
		background-color: darken($color: $red-color, $amount: 2%);
	}

	i {
		margin-right: 12px;
	}
}


.primary-btn {
	@extend %btn;
	background-color: $primary-color;
	
	&:hover {
		background-color: darken($color: $primary-color, $amount: 2%);
	}

	i {
		margin-right: 12px;
	}
}

.secondary-dark-btn {
	@extend %btn;
	background-color: $secondary-dark-color;
	
	&:hover {
		background-color: darken($color: $secondary-dark-color, $amount: 2%);
	}

	i {
		margin-right: 12px;
	}
}

.disable-btn {
	@extend %btn;
	background-color: $secondary-color;

	i {
		margin-right: 12px;
	}
}

// this is used to form submit on enter
.prime-submit-btn {
	position: absolute;
	z-index: -100;
	opacity: 0;
}