$page-title-height: 50px;
$page-header-height: 80px;

#dashboard {
	position: relative;

	.page-title {
        font-size: $sub-title !important;
        height: $page-title-height !important;
    }

	.page-content {
        height: calc(100% - #{$page-title-height}) !important;
		padding: 1.8em 4em;
        overflow: auto !important;

		.data-sections {
			width: 100% !important;
			padding: 1em;

			& > .flex-wrapper {
				align-items: center;
				justify-content: space-between;
			}

			.list-heading {
				color: rgba(255, 255, 255, 0.7) !important;
				padding-top: 0.6em !important;
				padding-bottom: 0.6em;
				padding-left: 0.6em !important;
			}

			.list-section {
				min-height: 16em;
			}
		}

		.exp-btn {
			width: auto;
			float: right;
		}
	}
}