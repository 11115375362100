
#change-password {
    padding: 2em;

    .title {
        font-size: $sub-title;
        color: $primary-color;
        font-weight: bold;
        padding-bottom: 0.9em;
        text-align: center;
    }

    .form-section {
        width: 100%;
        max-width: initial;
        background-color: transparent;
        margin-top: 0;
        padding: 0;

        .action-wrapper {
            width: 122px;
            margin-left: calc(30% + 36px);
        }
    }
}