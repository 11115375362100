// used in 
// DashboardDriverSearch
// DashboardVehicleSearch

#dashboard {
    .search-conteiner {
        margin: 26px;
        background-color: $secondary-light-color;
        display: flex;
        align-items: center;

        .icn {
            height: 1.8em;
            width: 2.2em;
            line-height: 1.5em;
            text-align: center;
            font-size: 1.2em;
            color: $primary-color;

            .fa {
                font-size: 0.9em;
            }

            &.clear-text {
                background-color: $red-soft-color;
                color: $red-color;
                border: 1px solid $red-color;
                transition: all .2s linear;
                cursor: pointer;
                margin-right: 0.22em;

                &:hover {
                    background-color: darken($color: $red-soft-color, $amount: 5%);
                }
            }

            &.magnifier {
                background-color: $green-soft-color;
                color: $green-color;
                border: 1px solid $green-color;
                transition: all .2s linear;
                cursor: pointer;
                margin-right: 0.22em;

                &:hover {
                    background-color: darken($color: $green-soft-color, $amount: 5%);
                }
            }
        }
    
        .search-input {
            padding: 0;
            padding-left: 1em;
            background-color: $secondary-light-color;
            color: $primary-color;
            font-weight: bold;
            height: 2.8em;
        }
    }

    .search-data-wrapper {
        margin: 24px;
        margin-top: 0.5em; // same as item-wrapper
        max-height: 300px;
        overflow: auto;

        .loading {
            color: #929292;
            text-align: center;
            padding: 40px 0;
        }

        .empty-msg {
            color: #929292;
            text-align: center;
            padding: 40px 0;
            font-size: 1.1em;
            font-weight: bold;
        }

        .item-wrapper {
            border-bottom: 1px solid $grey-color-3;
            padding: 0.5em 0.2em;
            letter-spacing: 0.4px;
            cursor: pointer;
            display: block;
            transition: background-color .2s;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: #f6f6f6;
            }

            .title {
                color: #7d7d7d;
                font-weight: bold;
                font-size: 1.06em;
                padding-bottom: 0.09em;
            }

            .desc {
                color: #929292;
                font-size: 0.96em;
            }
        }
    }
}